<template>
  <page-content>
    <v-toolbar flat>
      <app-tabs v-model="selectedTab">
        <v-tab v-for="tab of tabs" :key="tab.condition" :to="'#' + tab.id">{{ tab.name }}</v-tab>
      </app-tabs>
      <create-btn icon="mdi-home" text="Utwórz" tooltip="Nowy obiekt" @click="onNewSite"></create-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <app-toolbar>
      <v-col cols="12" md="9">
        <app-filter-field v-model="filter" @input="onFilter"></app-filter-field>
      </v-col>
      <v-col cols="12" md="3">
        <app-sort-select
          v-model="sortBySelected"
          :items="sortBy"
          :item-text="(item) => item.name"
        ></app-sort-select>
      </v-col>
    </app-toolbar>
    <v-divider></v-divider>
    <v-card-subtitle>
      <v-row align="center" justify="center">
        <v-col cols="6">Nazwa</v-col>
        <v-col cols="3">Adres</v-col>
        <v-col cols="3" align="center"> Data modyfikacji </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-subtitle v-for="site of sites" :key="site.id">
      <v-row align="center" class="text-body-2">
        <v-col cols="6">
          <site-link :site="site"></site-link>
          <!-- <site-info :site="site"></user-info> -->
          <!-- {{ site }} -->
        </v-col>
        <v-col cols="3">
          <site-address :site="site"></site-address>
        </v-col>
        <v-col cols="3" align="center">
          {{ site.modified_at | dateMedium }}
        </v-col>
        <v-col cols="12" class="py-0">
          <v-divider class=""></v-divider>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-actions class="justify-center">
      <v-pagination
        v-model="page"
        :length="pages"
        total-visible="8"
        circle
      ></v-pagination>
    </v-card-actions>
  </page-content>
</template>

<script>
import SiteService from "@/services/site.service"
import { sync } from 'vuex-pathify'

export default {
  name: "App",
  data() {
    return {
      tabs: SiteService.getMainConditions(),
      selectedTab: null,
      sortBy: SiteService.getMainOrdering(),
      sortBySelected: SiteService.getMainOrdering()[0],
      filter: "",
      sites: [],
      page: 1,
      pages: 2
    }
  },
  methods: {
    onNewSite() {
      this.$router.push({
        name: "New Site",
        params: {
          site: {
            site_type: 1
            // code_name: "X100",
            // name: "Rafal Modrzynski",
            // info: "wazny",
            // notice: "bardzo",
            // tags: "#cctv #integra",
            // password: "bajka",
            // city: "Gdansk",
            // distict: "Łostowice",
            // address: "Migdałowa 44"
          }
        }
      })
    },
    onFilter() {
      this.refresh()
    },
    refresh() {
      var filter = this.filter
      var conditions = this.tabs.find(t => t.id == this.selectedTab)?.conditions
      console.log('conditions :>> ', conditions);
      if (filter?.startsWith("#")) {
        conditions.push(`code_name__startswith=${filter.replace("#", "")}`)
        filter = ""
      }
      const ordering = this.sortBySelected.ordering

      SiteService.fetch(filter, conditions, this.page, ordering).then(data => {
        this.pages = data.pages
        this.sites = data.results
      })
    }
    // onFilter() {
    //   this.gridApi.setQuickFilter(this.filter)
    // },
    // onGridReady(params) {
    //   console.log("on grid ready")
    //   this.gridApi = params.api
    //   this.columnApi = params.columnApi
    // },
    // getSelectedRows() {
    //   console.log("getSelectedRows")
    //   const selectedNodes = this.gridApi.getSelectedNodes()
    //   const selectedData = selectedNodes.map(node => node.data)
    //   const selectedDataStringPresentation = selectedData
    //     .map(node => `${node.make} ${node.model}`)
    //     .join(", ")
    //   alert(`Selected nodes: ${selectedDataStringPresentation}`)
    // }
  },
  computed: {
    user: sync('user.profile'),
    sortByItems() {
      return this.sortBy.map(function(item) {
        return item.name
      })
    }
  },
  watch: {
    page() {
      this.refresh()
    },
    selectedTab() {
      console.log('selectedTab :>> ', this.selectedTab);
      this.refresh()
    },
    sortBySelected() {
      this.refresh()
    }
  },
  beforeMount() {
    this.refresh()
  }
}
</script>
